.button-style{
    min-width: 165px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 35px 0 35px;
    font-size: 15px;
    background-color: lightpink;
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    border: 0.5px solid lightpink;
}

.button-style:hover {
    background-color: white;
    color: lightpink;
    border: 0.5px solid lightpink;
}