
.landing-page{
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    background-attachment: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
  
  
  
  .landing p {
    color: black;
    font-weight: 500;
    font-size: 25px;
    text-align: center;
}
  

.main-button{
    min-width: 165px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 35px 50px 35px;
    font-size: 15px;
    background-color: lightpink;
    color: black;
    text-transform: uppercase;
    font-weight: bolder;
    border: none;
    cursor: pointer;
    margin-right: 5px;
    border: 3px solid lightpink;
}

.main-button:hover {
    min-width: 165px;
    width: auto;
    height: 50px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 35px 50px 35px;
    font-size: 15px;
    background-color: transparent;
    color: lightpink;
    border: 3px solid lightpink;
}

