
.test{
    margin-left: auto;
}

.link{
    color: darkblue !important;
    letter-spacing: 2px;
}




.navi{
    background-color: white !important;
}



.logo-brand{
    height: 40px;
    width: 190px;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='cornflowerblue' stroke-width='2.0' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
    color: cornflowerblue !important;
}

.custom-toggler.navbar-toggler{
    outline: none !important;
    box-shadow: none !important;
    border: none  !important;
    
}

.custom-toggler.navbar-toggler:active{
    border: none;
    outline: none;
    box-shadow: none;
    
}

.custom-toggler.navbar-toggler:focus{
    border: none;
    outline: none;
    box-shadow: none;
    
}

.image{
    display: block;
    width: 200px;
    height: 60px;
}