.page-footer{
    background-color: white !important;
    height:130px ;
    position: relative;
    left: 0;
    bottom: 0;
    right: 0;
    color: cornflowerblue;
    margin-top: 150px;


}





.ico1{
    color: cornflowerblue;
    font-size: 35px;
    margin: 20px;
}

