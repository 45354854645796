body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.main-sec{
    margin-top: 60px;
    margin-bottom: 60px;
}


.heading{
    color: cornflowerblue;
    font-size: 38px;
    font-weight: 500px;
    text-align: center;
}

.under-head{
    color: lightpink;
    font-size: 24px;
    margin-top: -10px;
    margin-bottom: 60px;
    text-align: center;
}

.under-head-2{
    color: lightpink;
    font-size: 28px;
    margin-top: -10px;
    margin-bottom: 60px;
    text-align: center;
}

.heading-landing{
    color: black;
    font-size: 38px;
    font-weight: 500px;
    text-align: center;
}

