main {
    min-height: 100vh;
    display: grid;
    place-items: center;
  }
  .title {
    text-align: center;
    margin-bottom: 4rem;
  }
  .underline {
    height: 0.25rem;
    width: 5rem;
    background: cornflowerblue;
    margin-left: auto;
    margin-right: auto;
  }

  .review {
    padding: 1.5rem 2rem;
    text-align: center;
  }

  .img-container {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin: 0 auto;
    margin-bottom: 1.5rem;
  }
  .person-img {
    width: 100%;
    display: block;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
  }
  .quote-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 2.5rem;
    height: 2.5rem;
    display: grid;
    place-items: center;
    border-radius: 50%;
    transform: translateY(25%);
    background: cornflowerblue;
    color: cornflowerblue;
  }
  .img-container::before {
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: -0.25rem;
    right: -0.5rem;
    border-radius: 50%;
  }
  .author {
    margin-bottom: 0.25rem;
  }
  .job {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 0.85rem;
  }
  .info {
    margin-bottom: 0.75rem;
  }
  .prev-btn,
  .next-btn {
    font-size: 1.25rem;
    background: transparent;
    border-color: transparent;
    margin: 0 0.5rem;
    cursor: pointer;
  }


.badge{
    background-color: green;
    padding: 40px;
    text-align: center;
}