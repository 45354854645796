.person{
    border: none !important;
    background-color: white;
}

.image-profile{
    border-radius: 50% !important;
    width: 220px !important;
    height: 220px !important;
    
    
}