.service{
    background-color: white !important;
    border: none !important;
    margin-top: -5px;
}

.icon{
    font-size: 50px;
    margin-bottom: 20px;
    color: cornflowerblue;
}

.accord{
    background-color: white !important;
    border: none !important;
}

.bod{
    margin-top: -10px;
}